import * as React from "react";
import { Link } from "gatsby";
import { Heading, Text } from "~/components/ui";
import { Page } from "~/components/ui";

export default function NotFound() {
  return (
    <Page>
      <Heading variant="mega">
        404
      </Heading>
      <Heading>Page not found</Heading>
      <div>
        <Text variant="lead">
          Sorry! We couldn’t find the page you were looking for.
        </Text>
        <Link to="/">
          <span>Back to home</span>
        </Link>
      </div>
    </Page>
  )
}
